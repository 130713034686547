import React, { useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import { useAuth } from '../context/authContext';
import Input from '../components/form/Input';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Validation from '../components/form/Validation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

type TValues = {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  password: string;
  confirmPassword: string;
};

const SignupPage = () => {
  const { onSignup } = useAuth();
  const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);
  const [confirmPasswordShowStatus, setConfirmPasswordShowStatus] = useState<boolean>(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      password: '',
      confirmPassword: '',
    },
    validate: (values) => {
      const errors: Partial<TValues> = {};
      if (!values.firstName) {
        errors.firstName = 'Required';
      }
      if (!values.lastName) {
        errors.lastName = 'Required';
      }
      if (!values.email) {
        errors.email = 'Required';
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.mobileNumber) {
        errors.mobileNumber = 'Required';
      }
      if (!values.password) {
        errors.password = 'Required';
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = 'Required';
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
      }
      return errors;
    },
    onSubmit: async (values: TValues) => {
      try {
        await onSignup(values.email, values.password);
        navigate('/login');
      } catch (error) {
        console.error('Sign-up failed', error);
      }
    },
  });

  return (
    <PageWrapper isProtectedRoute={false} className='bg-white dark:bg-gray-900' name='Sign Up'>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='container mx-auto flex h-full items-center justify-center'>
        <div className='flex flex-col gap-2 p-6 bg-white dark:bg-gray-800 border rounded-lg shadow-md border-gray-300 dark:border-gray-700' style={{ height: '620px', width: '100%', maxWidth: '400px' }}>
          <div className='flex flex-row items-start -mt-2 mb-1'>
            <LogoTemplate className='h-12' />
            <span className='text-3xl font-semibold ml-5 text-black dark:text-white'>Sign Up</span>
          </div>
          <div className='border border-zinc-500/25 dark:border-zinc-500/50 mb-2' />
          <form className='flex flex-col gap-2 flex-grow ' onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-2'>
              <FieldWrap
                isValid={formik.isValid}
                isTouched={formik.touched.firstName}
                invalidFeedback={formik.errors.firstName}
                validFeedback='Good'>
                <Input
                  dimension='lg'
                  id='firstName'
                  name='firstName'
                  placeholder='First Name'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
                />
              </FieldWrap>
            </div>
            <div className='mb-2'>
              <FieldWrap
                isValid={formik.isValid}
                isTouched={formik.touched.lastName}
                invalidFeedback={formik.errors.lastName}
                validFeedback='Good'>
                <Input
                  dimension='lg'
                  id='lastName'
                  name='lastName'
                  placeholder='Last Name'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
                />
              </FieldWrap>
            </div>
            <div className='mb-2'>
              <FieldWrap
                isValid={formik.isValid}
                isTouched={formik.touched.email}
                invalidFeedback={formik.errors.email}
                validFeedback='Good'>
                <Input
                  dimension='lg'
                  id='email'
                  type='email'
                  name='email'
                  placeholder='Email Address'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
                />
              </FieldWrap>
            </div>
            <div className='mb-2'>
              <FieldWrap
                isValid={formik.isValid}
                isTouched={formik.touched.mobileNumber}
                invalidFeedback={formik.errors.mobileNumber}
                validFeedback='Good'>
                <Input
                  dimension='lg'
                  id='mobileNumber'
                  name='mobileNumber'
                  placeholder='Mobile Number'
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
                />
              </FieldWrap>
            </div>
            <div className='mb-2'>
              <FieldWrap
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback='Good'
                lastSuffix={
                  <Icon
                    className='mx-2 cursor-pointer'
                    icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
                    onClick={() => setPasswordShowStatus(!passwordShowStatus)}
                  />
                }>
                <Input
                  dimension='lg'
                  type={passwordShowStatus ? 'text' : 'password'}
                  id='password'
                  name='password'
                  placeholder='Password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
                />
              </FieldWrap>
            </div>
            <div className='mb-2'>
              <FieldWrap
                isValid={formik.isValid}
                isTouched={formik.touched.confirmPassword}
                invalidFeedback={formik.errors.confirmPassword}
                validFeedback='Good'
                lastSuffix={
                  <Icon
                    className='mx-2 cursor-pointer'
                    icon={confirmPasswordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
                    onClick={() => setConfirmPasswordShowStatus(!confirmPasswordShowStatus)}
                  />
                }>
                <Input
                  dimension='lg'
                  type={confirmPasswordShowStatus ? 'text' : 'password'}
                  id='confirmPassword'
                  name='confirmPassword'
                  placeholder='Confirm Password'
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
                />
              </FieldWrap>
            </div>
            <div>
              <Button
                size='lg'
                variant='solid'
                className='w-full font-semibold'
                onClick={() => formik.handleSubmit()}>
                Sign Up
              </Button>
            </div>
            <p className='flex justify-center -mt-1 text-black dark:text-white'>Or</p>
            <div className='flex justify-center -mt-4'>
              <Button
                size='lg'
                className='w-full font-bold'
                onClick={() => navigate('/login')}>
                Already have an account? Sign In
              </Button>
            </div>
            <p className='flex justify-center text-black dark:text-white'>Or</p>
            <div className='flex flex-col -mt-2'>
              <Button
                 size='lg'
                 variant='solid'
                 className='w-full font-semibold mb-1'
                onClick={() => console.log('Google')}>
                <i className='fab fa-google mr-3'></i> Continue with Google
              </Button>
              <Button
                size='lg'
                variant='solid'
                className='w-full font-semibold'
                onClick={() => console.log('Facebook')}>
                <i className='fab fa-facebook-f mr-2'></i> Continue with Facebook
              </Button>
            </div>
          </form>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SignupPage;
