// import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
// import useLocalStorage from '../hooks/useLocalStorage';
// import { authPages } from '../config/pages.config';
// import useFakeUserAPI from '../mocks/hooks/useFakeUserAPI';
// import { TUser } from '../mocks/db/users.db';
// import { Data } from '../api';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// export interface IAuthContextProps {
// 	usernameStorage: string | ((newValue: string | null) => void) | null;
// 	onLogin: (username: string, password: string) => Promise<void>;
// 	onLogout: () => void;
// 	onSignup: (email: string, password: string) => Promise<void>; // Add this line
// 	userData: TUser;
// 	isLoading: boolean;
// }
// const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

// interface IAuthProviderProps {
// 	children: ReactNode;
// }
// export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
// 	const [usernameStorage, setUserName] = useLocalStorage('user', null);

// 	const { response, isLoading, getCheckUser } = useFakeUserAPI(usernameStorage as string);
// 	const userData = response as TUser;

// 	const navigate = useNavigate();

// 	// call this function when you want to authenticate the user
// 	const onLogin = async (username: string, password: string) => {
// 		await getCheckUser(username, password).then(async (values) => {
// 			if (typeof setUserName === 'function' && values?.statuscode === 200) {
// 				localStorage.setItem('loggedin', values.userid);
// 				Data();
// 				await setUserName(username).then(() => navigate('/'));
// 			} else {
// 				toast.error('Invalid Username/Password!', {
// 					position: 'top-right',
// 					autoClose: 3000,
// 					hideProgressBar: false,
// 					closeOnClick: true,
// 					pauseOnHover: true,
// 					draggable: true,
// 					progress: undefined,
// 				});
// 				navigate('/login');
// 			}
// 		});
// 	};

// 	// New sign-up function
//   const onSignup = async (email: string, password: string) => {
//     try {
//       // Replace with your actual sign-up logic
//       // For example, you might call an API here
//       console.log('Signing up with:', email, password);
//       // Simulate API call
//       const response = await fakeSignupAPI(email, password);
//       if (response.status === 200) {
//         toast.success('Sign up successful!', {
//           position: 'top-right',
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });
//       } else {
//         throw new Error('Sign-up failed');
//       }
//     } catch (error) {
//       toast.error('Sign-up failed!', {
//         position: 'top-right',
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//     }
//   };

// 	// call this function to sign out logged-in user
// 	const onLogout = async () => {
// 		if (typeof setUserName === 'function') await setUserName(null);
// 		navigate(`../${authPages.loginPage.to}`, { replace: true });
// 	};



// 	const value: IAuthContextProps = useMemo(
// 		() => ({
// 			usernameStorage,
// 			onLogin,
// 			onLogout,
// 			onSignup,
// 			userData,
// 			isLoading,
// 		}),
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 		[usernameStorage, userData , isLoading],
// 	);
// 	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// };

// export const useAuth = () => {
// 	return useContext(AuthContext);
// };


import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import useFakeUserAPI from '../mocks/hooks/useFakeUserAPI'; // Import the updated hook
import { TUser } from '../mocks/db/users.db';
import { Data } from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authPages } from '../config/pages.config'; // Ensure this is correctly imported

export interface IAuthContextProps {
  usernameStorage: string | ((newValue: string | null) => void) | null;
  onLogin: (username: string, password: string) => Promise<void>;
  onLogout: () => void;
  onSignup: (email: string, password: string) => Promise<void>;
  userData: TUser;
  isLoading: boolean;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const [usernameStorage, setUserName] = useLocalStorage('user', null);

  const { response, isLoading, getCheckUser, signupUser } = useFakeUserAPI(usernameStorage as string);
  const userData = response as TUser;

  const navigate = useNavigate();

  const onLogin = async (username: string, password: string) => {
    try {
      const values = await getCheckUser(username, password);
      if (values?.statuscode === 200) {
        if (typeof setUserName === 'function') {
          localStorage.setItem('loggedin', values.userid);
          Data(); // Ensure this function is correctly used
          await setUserName(username);
          navigate('/');
        }
      } else {
        throw new Error('Invalid Username/Password');
      }
    } catch (error) {
      // toast.error('Invalid Username/Password!', {
      //   position: 'top-right',
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      navigate('/login');
    }
  };

  const onSignup = async (email: string, password: string) => {
    try {
      const response = await signupUser(email, password);
      if (response?.status === 200) {
        toast.success('Sign up successful!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate('/login'); 
      } else {
        throw new Error('Sign-up failed');
      }
    } catch (error) {
      toast.error('Sign-up failed!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onLogout = async () => {
    try {
      if (typeof setUserName === 'function') await setUserName(null);
      navigate(`../${authPages.loginPage.to}`, { replace: true });
    } catch (error) {
      toast.error('Logout failed!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const value: IAuthContextProps = useMemo(
    () => ({
      usernameStorage,
      onLogin,
      onLogout,
      onSignup,
      userData,
      isLoading,
    }),
    [usernameStorage, userData, isLoading]
  );

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
